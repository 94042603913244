class Data_modifier {
  constructor(data) {
    // original data fetched from server
    // we used dns resolver on server side
    // and added asn-descripyion
    // and asn country-code on each row of
    // record
    this.data = data;
  }
  // this method groups all the ip's
  // based on their asn-description that is organisation-eg google
  // and groups by email server as well
  group_by_organisation = (data) => {
    var new_record = {};
    var domain_list = [];
    const check_if_email_server_is_already_present = (
      record,
      org_name,
      new_record
    ) => {
      let result = [false, -1];
      new_record[org_name].forEach((item, index_search) => {
        if (item["hostname"] === record["hostname"]) {
          result = [true, index_search];
          return result;
        }
      });
      return result;
    };
    const domain_mapper = (record, new_record, domain_list) => {
      let org_name = record["asn_desription"];
      if (domain_list.includes(org_name)) {
        let [result, index_search] = check_if_email_server_is_already_present(
          record,
          org_name,
          new_record
        );
        if (result) {
          new_record[org_name][index_search].volume =
            parseInt(new_record[org_name][index_search].volume) +
            parseInt(record.row.count);
        } else {
          new_record[org_name].push({
            volume: record.row.count,
            hostname: record.hostname,
            ip: record.row.source_ip,
            country: record.asn_country_code,
          });
        }
      } else {
        domain_list.push(org_name);
        new_record[org_name] = [];
        new_record[org_name].push({
          volume: record.row.count,
          hostname: record.hostname,
          ip: record.row.source_ip,
          country: record.asn_country_code,
        });
      }
    };
    // the main loop

    this.data.hits.hits.forEach((hit) => {
      if (Array.isArray(hit._source.feedback.record)) {
        hit._source.feedback.record.forEach((record) =>
          domain_mapper(record, new_record, domain_list)
        );
      } else {
        domain_mapper(hit._source.feedback.record, new_record, domain_list);
      }
    });

    return new_record;
  };
  // we have ip records as seperate data
  // which is a collection of aggrgrated ip's wwith their dmarc status
  // so now we reverse lookup from the results from method above and get
  //  a records which is aggrgrated by organisation and has all dmarc results
  results_by_organisation_analysed = (ip_records, asn_grouped_results) => {
    const look_for_ip = (record_dmarc) => {
      let keys = Object.keys(asn_grouped_results);
      keys.forEach((org_name) => {
        let ip_record_arrays = asn_grouped_results[org_name];
        ip_record_arrays.forEach((record_org) => {
          if (record_dmarc.ip == record_org.ip) {
            // console.log("found ip");
            record_dmarc.asn_description = org_name;
            record_dmarc.country = record_org.country;
            record_dmarc.hostname = record_org.hostname;
          }
        });
      });
    };

    ip_records.forEach((record_dmarc) => {
      look_for_ip(record_dmarc);
    });
    let grouped_records = {};
    let org_list = [];
    ip_records.forEach((record, index) =>
      ((grouped_records, record, org_list) => {
        if (org_list.includes(record.asn_description)) {
          grouped_records[record.asn_description].push(ip_records[index]);
        } else {
          org_list.push(record.asn_description);
          grouped_records[record.asn_description] = [];
          grouped_records[record.asn_description].push(ip_records[index]);
        }
      })(grouped_records, record, org_list)
    );
    return grouped_records;
  };

  //   end of class
}

export { Data_modifier };
