import React, { useEffect, useState } from "react";
import FileUploadPage from "./metadataupload";
import { AddUsers, ShowUsers } from "./manageSamlUsers";
import { Button, Modal, Nav, Row, Container } from "react-bootstrap";
import Wizard from "../Subscription/WizardFooter/Wizard";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ReportGenerator } from "../Report/reportgenerator";
import { Overlay } from "../uihelpers/overlay.js";
import { useReactPath } from "../../hooks/useReactPath";
const dmarc_path = process.env.REACT_APP_DMARC_ROUTE;

function ModalContainer(props) {
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    props.openModal(false);
  };
  return (
    <>
      <Modal show={true} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{React.createElement(props.component, props)}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const SAMLSetup = (props) => {
  const [reload, setreload] = useState(false);
  const [response, setresponse] = useState(false);
  let host = process.env.REACT_APP_SERVERLESS_LOGIN;

  const remove_saml_user = (e) => {
    const user_to_remove =
      e.target.parentElement.parentElement.children[1].textContent;
    alert(`Are you sure you want to remove ${user_to_remove}`);

    const removeUser = async () => {
      let formData = new FormData();
      formData.append("user_to_remove", user_to_remove);
      const response = await fetch(`${host}/api/auth/saml/remove_saml_users`, {
        method: "post",
        credentials: "include",
        body: formData,
      });

      let user_remove_response = await response.json();
      if (user_remove_response) {
        if (user_remove_response.status === "success") {
        }
        alert(user_remove_response.msg);
        setresponse(user_remove_response);
        // change the state so all of it rerenders,
        // the users component have a useeffect dependency on this
        // state passed through props
        setreload(!reload);
      }
    };

    removeUser();
  };
  return (
    <>
      <div className="adminserviceswrapper" style={{ marginBottom: "35px" }}>
        <div
          className="bg-box rounded-xl prevent-overflow"
          style={{
            width: "100%",
            marginBlock: "35px",
          }}
        >
          <div className="box-header" style={{ backgroundColor: "#3a64a2" }}>
            Submit IDP Metadata
          </div>
          <FileUploadPage
            setoverlaystatus={props.setoverlaystatus}
          ></FileUploadPage>
        </div>
        <div className={"userdisplaywrapper"} style={{ width: "100%" }}>
          <div className="wrapper bg-box rounded-xl prevent-overflow">
            <div className="box-header" style={{ backgroundColor: "#3a64a2" }}>
              Current Saml Users
            </div>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <th>Role</th>
                  <th>User</th>
                  <th>Authorised Domains</th>
                  <th></th>
                </thead>
                <tbody>
                  <ShowUsers
                    setoverlaystatus={props.setoverlaystatus}
                    response={response}
                    setresponse={setresponse}
                    remove_user={remove_saml_user}
                    reload={reload}
                  ></ShowUsers>
                </tbody>
              </table>
            </div>
          </div>
          <div className="bg-box rounded-xl prevent-overflow">
            <div className="box-header" style={{ backgroundColor: "#3A64A2" }}>
              Add SAML Users
            </div>
            <AddUsers
              setoverlaystatus={props.setoverlaystatus}
              reload={reload}
              response={response}
              setresponse={setresponse}
              setreload={setreload}
            ></AddUsers>
          </div>
        </div>
      </div>
    </>
  );
};

const SuccessPage = () => {
  let host = process.env.REACT_APP_BACKEND;
  const urlSearchParams = new URLSearchParams(window.location.search);
  let session_id = urlSearchParams.get("session_id");
  let user_info = urlSearchParams.get("code");
  const [cust_info_stripe, set_cust_info_stripe] = useState("");

  const customer_info = async () => {
    let formData = new FormData();
    formData.append("stripe_info", session_id);
    formData.append("user_info", user_info);
    const response = await fetch(`${host}/payment/subscription/success`, {
      credentials: "include",
      method: "post",
      body: formData,
    });
    let result = await response.json();
    console.log(result);
    set_cust_info_stripe(result);
  };

  useEffect(() => {
    customer_info();
  }, []);
  return (
    <>
      {" "}
      {cust_info_stripe === "success" ? (
        <>
          <div>Thanks {`${cust_info_stripe.name} for your order.`}</div>
          <Button>Configure your product</Button>
        </>
      ) : (
        <>
          <div className="error alert alert-danger">{cust_info_stripe.msg}</div>
          <a href="/">
            <button className="btn btn-success">Go to Dashboard</button>
          </a>
        </>
      )}
    </>
  );
};

const ManageSubscription = () => {
  let host = process.env.REACT_APP_BACKEND;
  const customer_portal = async () => {
    const response = await fetch(`${host}/payment/stripe-payment-management`, {
      credentials: "include",
      method: "post",
    });
    let result = await response.json();
    console.log(result);
    window.location.replace(result.redirect_url);
  };

  useEffect(() => {
    customer_portal();
  }, []);

  return <div>Redirecting to stripe customer portal...</div>;
};

const UpdateSubscription = (props) => {
  const [handleUpgradeButton, sethandleUpgradeButton] = useState(false);
  const handleUpgrade = (e) => {
    sethandleUpgradeButton(true);
  };

  return (
    <>
      <div>
        Your current Subscription is{" "}
        {props.currentUser ? props.currentUser.subscriptionType : "Basic"}
      </div>
      {handleUpgradeButton ? (
        <Button disabled onClick={(e) => handleUpgrade(e)}>
          Update Subscription
        </Button>
      ) : (
        <Button onClick={(e) => handleUpgrade(e)}>Update Subscription</Button>
      )}
      {handleUpgradeButton ? <Wizard></Wizard> : ""}
    </>
  );
};

const displayAdminSideBar = () => {
  const path = [
    `/${dmarc_path}/adminpanel/settings/subscription`,
    `/${dmarc_path}/adminpanel/settings/saml`,
  ];
  if (path.indexOf(window.location.pathname) !== -1) {
    return true;
  } else {
    return false;
  }
};

const paths = {
  saml: `/${dmarc_path}/adminpanel/settings/saml`,
  subscription: `/${dmarc_path}/adminpanel/settings/subscription`,
};
const AdminSideBar = (props) => {
  const { path } = useReactPath();
  const [currentlySelectedMenuItem, setcurrentlySelectedMenuItem] =
    useState(false);
  const pathIs = (p) => (path === p ? " active " : "");

  let host = process.env.REACT_APP_FRONTEND;
  return (
    <Nav
      className="d-md-block"
      activeKey="/home"
      onSelect={(selectedKey) => setcurrentlySelectedMenuItem(selectedKey)}
      style={{
        padding: "20px 0 20px 0",
        maxWidth: "12%",
        minWidth: "calc(100px + 15vw)",
      }}
    >
      <div
        className="bg-box rounded-xxl sidebar"
        style={{
          alignItems: "flex-start",
          backgroundColor: "#F7F4F4",
          position: "relative",
        }}
      >
        <div style={{ position: "sticky", top: "20px" }}>
          <Nav.Item>
            <Nav.Link href="/" className={"isLink"}>
              Home
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href={paths.saml}
              className={"isLink" + pathIs(paths.saml)}
            >
              SAML Settings
            </Nav.Link>
          </Nav.Item>
          {props.currentUser.paymentType ? (
            <Nav.Item className="sub-menu-parent-displayed">
              <Nav.Link
                className="isLink"
                onClick={(e) =>
                  (window.location = `${host}/${dmarc_path}/adminpanel/settings/subscription/manage`)
                }
                eventKey="Manage Payment"
              >
                Manage Payment
              </Nav.Link>
            </Nav.Item>
          ) : (
            ""
          )}
          <Nav.Item>
            <Nav.Link
              href={paths.subscription}
              className={"isLink" + pathIs(paths.subscription)}
            >
              Subscription
            </Nav.Link>
          </Nav.Item>
          {path === paths.subscription && (
            <Nav.Item className="sub-menu-parent-displayed">
              <Nav.Link
                className="isLink"
                onClick={(e) => {
                  props.modalHandler({ selected: e.target.value, open: true });
                }}
                eventKey="Update Subscription"
              >
                Update Subscription
              </Nav.Link>
            </Nav.Item>
          )}
        </div>
      </div>
    </Nav>
  );
};

const AdminSideBaronSideMenu = (props) => {
  const [currentlySelectedMenuItem, setcurrentlySelectedMenuItem] =
    useState(false);
  let host = process.env.REACT_APP_FRONTEND;
  return (
    <Nav
      className="col-md-2 d-md-block bg-light sidebar"
      activeKey=""
      onSelect={(selectedKey) => setcurrentlySelectedMenuItem(selectedKey)}
    >
      <div className="sidebar-sticky"></div>

      <Nav.Item>
        <Nav.Link
          onClick={(e) => {
            props.modalHandler({ selected: e.target.value, open: true });
          }}
          eventKey="Update Subscription"
        >
          Update Subscription
        </Nav.Link>
      </Nav.Item>
      {props.currentUser.paymentType ? (
        <Nav.Item>
          <Nav.Link
            onClick={(e) =>
              (window.location = `${host}/${dmarc_path}/adminpanel/settings/subscription/manage`)
            }
            eventKey="Manage Payment"
          >
            Manage Payment
          </Nav.Link>
        </Nav.Item>
      ) : (
        ""
      )}
    </Nav>
  );
};

const AdminInterFace = (props) => {
  const [openModal, setopenModal] = useState(false);
  const [reload, setreload] = useState(false);
  const [overlaystatus, setoverlaystatus] = useState(false);

  return (
    <Container fluid>
      <Overlay activate={overlaystatus} text_to_show={"loading data"}></Overlay>
      <Row style={{ gap: "20px", paddingInline: "20px" }}>
        {displayAdminSideBar() ? (
          <AdminSideBar
            modalHandler={setopenModal}
            currentUser={props.currentUser}
          ></AdminSideBar>
        ) : (
          ""
        )}

        <Router>
          <Switch>
            <Route exact path="/dmarc/adminpanel/settings/saml">
              {props.currentUser ? (
                <>
                  {" "}
                  <div
                    className="admin-content-wrapper"
                    // style={{ width: "min-content" }}
                  >
                    <div className="saml-settings-page-header">
                      SAML Settings
                    </div>
                    <SAMLSetup
                      setoverlaystatus={setoverlaystatus}
                      refresh={reload}
                      setrefresh={setreload}
                    ></SAMLSetup>
                  </div>
                </>
              ) : (
                <div className="content-centre">
                  <div className="spinner-border text-primary"></div>
                  <div>Loading settings data...</div>
                </div>
              )}
            </Route>

            <Route exact path="/dmarc/adminpanel/settings/subscription">
              <div className="admin-content-wrapper-subscription">
                <table className="table subscription-details table-striped rounded-xl prevent-overflow">
                  <thead className="thead-light">
                    <tr scope="row">
                      <th
                        style={{ backgroundColor: "#3A64A2", color: "white" }}
                        scope="col"
                      >
                        Current Subscription Type
                      </th>
                      <th
                        style={{ backgroundColor: "#3A64A2", color: "white" }}
                        scope="col"
                      >
                        Payment Type
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {props.currentUser
                          ? props.currentUser.subscriptionType
                          : "basic"}
                      </td>
                      <td>
                        {props.currentUser.paymentType
                          ? props.currentUser.paymentType.toUpperCase()
                          : "Not any"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* <Button onClick = {()=>setShowsubscriptionDialog(!showSubscriptionDialog)}>Manage Subscription</Button> */}
              {openModal.open ? (
                <ModalContainer
                  openModal={setopenModal}
                  title={openModal.selected}
                  component={UpdateSubscription}
                  currentUser={props.currentUser}
                ></ModalContainer>
              ) : (
                ""
              )}
            </Route>

            <Route exact path="/dmarc/adminpanel/settings/subscription/success">
              <SuccessPage></SuccessPage>
            </Route>
            {/* dont show manage payment if no payment is defined */}

            <Route exact path="/dmarc/adminpanel/settings/subscription/manage">
              <ManageSubscription></ManageSubscription>
            </Route>
            <Route exact path="/dmarc/adminpanel/reports">
              <ReportGenerator></ReportGenerator>
            </Route>
            <Route exact path="/dmarc/adminpanel">
              <div className="adminpanel-home-containers">
                <div className="individual-home-containers">
                  <div className="img-container"></div>
                  <div className="container-text">
                    <span></span>
                  </div>
                  <div>
                    <button className="btn btn-primary">
                      <a
                        className="btn btn-primary"
                        href="./adminpanel/settings/saml"
                      >
                        <span>Manage SAML</span>
                      </a>
                    </button>
                  </div>
                </div>
                <div className="individual-home-containers">
                  <div className="img-container"></div>
                  <div className="container-text">
                    <span></span>
                  </div>
                  <div>
                    <button className="btn btn-primary">
                      <a
                        className="btn btn-primary"
                        href="./adminpanel/settings/subscription"
                      >
                        <span>Manage Subscription</span>
                      </a>
                    </button>
                  </div>
                </div>
                <div className="individual-home-containers">
                  <div className="img-container"></div>
                  <div className="container-text">
                    <span></span>
                  </div>
                  <div>
                    <button className="btn btn-primary">
                      <a
                        className="btn btn-primary"
                        href="./adminpanel/reports"
                      >
                        <span>Download Reports</span>
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </Route>
          </Switch>
        </Router>
      </Row>
    </Container>
  );
};

export { AdminInterFace, AdminSideBaronSideMenu };
