import React, { useState, useEffect } from "react";
import { initApp, firebase } from "../../firebase.js";

const VerifyEmail = () => {
  let timeout = 30;
  const [isVerificationDisabled, setVerificationDisabled] = useState(true);
  const [isVerificationClicked, setVerificationClicked] = useState(false);
  const [currentCount, setCount] = useState(timeout);
  // a update timer function
  const timer = () => setCount(currentCount - 1);

  useEffect(() => {
    if (currentCount <= 0) {
      setVerificationDisabled(false);
      return;
    }
    const id = setInterval(timer, 1000);
    return () => clearInterval(id);
  }, [currentCount]);

  return (
    <>
      <div className="unverified-email">
        {!isVerificationClicked ? (
          <p>
            You are nearly there, look for the verification email in your inbox.
          </p>
        ) : (
          ""
        )}
      </div>
      <div className="form-inline timerblock">
        {!isVerificationClicked ? (
          <>
            <button
              disabled={isVerificationDisabled}
              className="btn-verification"
              onClick={() => {
                firebase.auth().currentUser.sendEmailVerification();
                setVerificationClicked(true);
              }}
            >
              Resend Verification Link
            </button>
            <div className="timerblock-timer">
              {currentCount != 0 ? (
                <>
                  <span>Verification link will be active in</span>
                  <pre>{currentCount} secs</pre>
                </>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <div className="verify-instructions">
            <div>
              An email has been sent to the user registered email address
            </div>
            <div>
              Please click the link in the email to verify your email address
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export { VerifyEmail };
