import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, Spinner } from "react-bootstrap";
import homeimage from "../static/Data_security_28.jpg";
import "../dmarchome.css";
import { FileEarmarkEasel, NodeMinus } from "react-bootstrap-icons";

const DmarcHome = (props) => {
  let host = process.env.REACT_APP_BACKEND;
  const [inputValue, setinputValue] = useState(false);
  const [dmarcLoader, setdmarcLoader] = useState(false);
  const [showdmarcReports, setshowdmarcReports] = useState(false);
  const [dmarcresponse, setdmarcresponse] = useState(false);
  const [spfLoader, setspfLoader] = useState(false);
  const [showspfReports, setspfReports] = useState(false);
  const [spfresponse, setspfresponse] = useState(false);
  const [spferror, setspferror] = useState(false);
  const [dmarcerror, setdmarcerror] = useState(false);
  const [showCollapsible, setShowCollapsible] = useState(false);

  const handleChange = (e) => {
    setinputValue(e.target.value);
    setdmarcerror(false);
    setspferror(false);
    setshowdmarcReports(false);
    setspfresponse(false);
  };

  const checkDomain = async (domain) => {
    await getdmarc(domain);
    await checkSpf(domain);
  };

  const getdmarc = async (domain) => {
    setdmarcLoader(true);
    setshowdmarcReports(true);
    let response = await fetch(`${host}/tools/dmarccheck?domain=${domain}`, {
      method: "get",
    });
    let json_response = await response.json();
    if (json_response.error !== true) {
      setdmarcresponse(json_response);
      setdmarcLoader(false);
      return json_response;
    } else {
      setdmarcerror(json_response);
      setdmarcLoader(false);
    }
  };

  const checkSpf = async (domain) => {
    setspfLoader(true);

    let response = await fetch(`${host}/tools/spfcheck?domain=${domain}`, {
      method: "get",
    });
    let json_response = await response.json();

    if (json_response.error !== true) {
      setspfresponse(json_response);
      setspfLoader(false);
      setspfReports(true);
      return json_response;
    } else {
      setspferror(json_response);
      setspfLoader(false);
      setspfReports(false);
    }
    return json_response;
  };

  const DmarcReports = (props) => {
    return (
      <>
        {dmarcLoader ? (
          <Spinner animation="border" variant="primary" role="status">
            <span className="sr-only">Loading dmarc data...</span>
          </Spinner>
        ) : (
          " "
        )}
        {!dmarcerror ? (
          <>
            {showdmarcReports ? (
              <>
                {
                  dmarcresponse.dmarc_reports ? (
                    <>
                      <div className="dmarc-header">
                        Congrats,you have DMARC enabled
                      </div>
                      <div className="dmarc-info-container">
                        <table className="table table-dmarc table-bordered table-striped">
                          <tbody>
                            <tr>
                              <th className="dmarc-header" colSpan={4}>
                                Your DMARC Details
                              </th>
                            </tr>
                            <tr>
                              <td>v</td>
                              <td>{dmarcresponse.dmarc_record_details.v}</td>
                              <td>
                                The DMARC version should always be "DMARC1".
                                Note: A wrong, or absent DMARC version tag would
                                cause the entire record to be ignored
                              </td>
                            </tr>
                            <tr>
                              <td>p</td>
                              <td>{dmarcresponse.dmarc_record_details.p}</td>
                              <td>
                                Policy applied to emails that fails the DMARC
                                check. Authorized values: "none", "quarantine",
                                or "reject". "none" is used to collect feedback
                                and gain visibility into email streams without
                                impacting existing flows. "quarantine" allows
                                Mail Receivers to treat email that fails the
                                DMARC check as suspicious. Most of the time,
                                they will end up in your SPAM folder. "reject"
                                outright rejects all emails that fail the DMARC
                                check.
                              </td>
                            </tr>
                            <tr>
                              <td>sp</td>
                              <td>{dmarcresponse.dmarc_record_details.sp}</td>
                              <td>
                                Policy to apply to email from a sub-domain of
                                this DMARC record that fails the DMARC check.
                                Authorized values: "none", "quarantine", or
                                "reject". This tag allows domain owners to
                                explicitly publish a "wildcard" sub-domain
                                policy.
                              </td>
                            </tr>

                            <tr>
                              <td>fo</td>
                              <td>{dmarcresponse.dmarc_record_details.fo}</td>
                              <td>
                                Forensic reporting options. Authorized values:
                                "0", "1", "d", or "s". "0" generates reports if
                                all underlying authentication mechanisms fail to
                                produce a DMARC pass result, "1" generates
                                reports if any mechanisms fail, "d" generates
                                reports if DKIM signature failed to verify, "s"
                                generates reports if SPF failed.
                              </td>
                            </tr>
                            <tr>
                              <td>rua</td>
                              <td>
                                {typeof dmarcresponse.records.rua == "object"
                                  ? dmarcresponse.records.rua.map(
                                      (items) => items + "; "
                                    )
                                  : dmarcresponse.records.rua}
                              </td>
                              <td>
                                The email address where your dmarc reports are
                                sent
                              </td>
                            </tr>
                            <tr>
                              <td>ruf</td>
                              <td>
                                {typeof dmarcresponse.records.ruf == "object"
                                  ? dmarcresponse.records.ruf.map(
                                      (items) => items + "; "
                                    )
                                  : dmarcresponse.records.ruf}
                              </td>
                              <td>
                                The email address where your forensic reports
                                are sent
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    <div></div>
                  ) //end of dmarc reports block
                }
              </>
            ) : (
              " "
            )}
          </>
        ) : (
          <div className="dmarc-error">
            DMARC ERROR :
            {" " + dmarcerror.errormsg + " " + dmarcerror.records.error}
          </div>
        )}
      </>
    );
  };

  const SpfReport = (props) => {
    let parsedResponse = spfresponse;
    const includes_key_list = Object.keys(
      parsedResponse.individual_includes_response
    );
    const includes = parsedResponse.individual_includes_response;
    const depth = parsedResponse.depth;
    const duplicates = (parsedResponse) =>
      parsedResponse["duplicates"] ? parsedResponse["duplicates"] : false;

    const mapParsedResponse = () => {
      let htmlcontent = [];
      let content = "";
      includes_key_list.map((include_domain) => {
        if (includes[include_domain] !== null) {
          if (includes[include_domain]["children"] !== undefined) {
            htmlcontent.push(
              <div className="parent_domain">
                <span className="parent-length">
                  {includes[include_domain]["children"].length}
                </span>
                <span className="parent-name">{include_domain}</span>
                <span className="warning">
                  &#40;requires multiple queries&#41;
                </span>
              </div>
            );
            content = includes[include_domain]["children"].map(
              (domain, index) => {
                return (
                  <div className="entry child-container">
                    {/* <div className="parent_domain">{(includes[include_domain]["children"]).length}{include_domain}</div> */}
                    <div className="child_domain name">
                      {
                        includes[include_domain]["children"][index]
                          .queried_domain
                      }
                    </div>
                    {showCollapsible ? (
                      <div>
                        {includes[include_domain]["children"][index].ip4
                          .length > 0 ? (
                          <div className="child_domain ip">
                            {includes[include_domain]["children"][
                              index
                            ].ip4.map((ip) => (
                              <li>{ip}</li>
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                        {includes[include_domain]["children"][index].ip6
                          .length > 0 ? (
                          <div className="child_domain ip">
                            {includes[include_domain]["children"][
                              index
                            ].ip6.map((ip) => (
                              <li>{ip}</li>
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="child_domain spf_record">
                          <span>SPF Record</span>
                          {
                            includes[include_domain]["children"][index]
                              .txt_records
                          }
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              }
            );
            htmlcontent.push(content);
          } else {
            if (includes[include_domain].queried_domain !== undefined) {
              htmlcontent.push([
                <div className="entry">
                  <div className="single name">
                    <span>1</span>
                    {includes[include_domain].queried_domain}
                  </div>
                  {showCollapsible ? (
                    <div>
                      {includes[include_domain].ip4.length > 0 ? (
                        <div className="single ip">
                          {includes[include_domain].ip4.map((ip) => (
                            <li>{ip}</li>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                      {includes[include_domain].ip6.length > 0 ? (
                        <div className="single ip">
                          {includes[include_domain].ip6.map((ip) => (
                            <li>{ip}</li>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="single spf_record">
                        {includes[include_domain].txt_records}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>,
              ]);
            }
          }
        }
      });
      return htmlcontent;
    };

    let parsedResponsehtml = mapParsedResponse();

    return (
      <div>
        {spfLoader ? (
          <Spinner animation="border" variant="primary" role="status">
            <span className="sr-only">Loading data...</span>
          </Spinner>
        ) : (
          " "
        )}
        {SpfReport ? (
          spfresponse ? (
            <>
              <div className="record-container">
                <div className="record-header">
                  SPF record for {parsedResponse["queried_domain"]}
                </div>
                <div className=" entry main_queried_domain">
                  <div className="entry record-text">
                    <span className="inline-text positive">SPF Record: </span>
                    <span className="inline-text positive">
                      {parsedResponse["txt_records"]}
                    </span>
                  </div>
                  <div className="entry record-text depth">
                    The SPF resolution took
                    {parsedResponse["individual_includes_response"]["depth"] >
                    10 ? (
                      <span className="depth-number-warning">
                        <span className="warning">
                          {
                            parsedResponse["individual_includes_response"][
                              "depth"
                            ]
                          }
                          &#40;too many&#41;
                        </span>
                        :
                      </span>
                    ) : (
                      <span className="depth-number">
                        <span>
                          {
                            parsedResponse["individual_includes_response"][
                              "depth"
                            ]
                          }
                        </span>
                      </span>
                    )}
                    queries.Anthing less or equal to 10 is accepted or it may
                    cause email deliverability problems.
                  </div>
                  {parsedResponse["ip4"].length > 0 ? (
                    <div className="entry ip">
                      <span
                        style={{
                          marginBottom: "10px",
                          display: "inline-block",
                        }}
                        className="inline-text-positive text"
                      >
                        Allowed IP
                      </span>
                      <span className="inline-text positive">
                        {parsedResponse["ip4"].map((ip) => (
                          <li>{ip}</li>
                        ))}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {parsedResponse["ip6"].length > 0 ? (
                    <div className="entry ip">
                      <span className="inline-text positive">Allowed IP</span>
                      <span className="inline-text positive">
                        {parsedResponse["ip6"].map((ip) => (
                          <li>{ip}</li>
                        ))}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {parsedResponse["duplicates"] ? (
                    <div className="entry duplicates">
                      <span className="inline-text-negative number">
                        {parsedResponse["duplicates"].count}{" "}
                      </span>
                      <span className="inline-text-negative text">
                        {" "}
                        duplicate ip addresses found
                      </span>
                      <div className="duplicates-ip">
                        {parsedResponse["duplicates"].ips.map((ip) => (
                          <li>{ip}</li>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="entry duplicates">
                        <span className="inline-text-positive number">
                          {0}{" "}
                        </span>
                        <span className="inline-text-positive text">
                          {" "}
                          duplicate ip addresses found
                        </span>
                      </div>
                    </>
                  )}
                  <div className="expander-button-container">
                    <button
                      disabled={showCollapsible}
                      onClick={(e) => {
                        setShowCollapsible(true);
                      }}
                      className="btn expander-button"
                    >
                      Expand All
                    </button>
                    <button
                      disabled={!showCollapsible}
                      onClick={() => {
                        setShowCollapsible(false);
                      }}
                      className="btn collapser-button"
                    >
                      Collapse All
                    </button>
                  </div>
                  {parsedResponsehtml}
                </div>
              </div>
            </>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    );
  };

  const DKIMReport = () => {
    const [inputValuedkim, setinputValuedkim] = useState(false);
    const [dkimresponse, setdkimresponse] = useState(false);
    const [dkimerror, setdkimerror] = useState(false);
    const [dkimreport, setdkimreport] = useState(false);
    const [dkimLoader, setdkimloader] = useState(false);

    const handleChangedkimSelector = (e) => {
      setinputValuedkim(e.target.value);
      setdkimerror(false);
      setdkimreport(false);
    };

    const checkdkim = async (domain, selector) => {
      setdkimreport(true);
      setdkimloader(true);
      let response = await fetch(
        `${host}/tools/dkimcheck?domain=${domain}&selector=${selector}`,
        {
          method: "get",
        }
      );
      let json_response = await response.json();
      if (json_response.error !== true) {
        setdkimresponse(json_response);
        setdkimloader(false);
        setdkimerror(false);
        return json_response;
      } else {
        setdkimreport(true);
        setdkimerror(json_response);
        setdkimloader(false);
      }
    };

    return (
      <>
        <div className="search-form">
          <span>Check your DKIM Record</span>
          <div>Currently inspecting {inputValue}</div>
          <input
            className="form-input-search"
            onChange={handleChangedkimSelector}
            placeholder="Enter DKIM selector"
          ></input>
          <button
            disabled={!inputValuedkim}
            onClick={() => checkdkim(inputValue, inputValuedkim)}
            className="btn btn-primary"
          >
            Inspect DKIM
          </button>
        </div>
        {dkimerror ? (
          <>
            <div className="dkim-error">DKIM Error :{dkimerror.errormsg}</div>
          </>
        ) : (
          ""
        )}

        {dkimreport ? (
          dkimLoader ? (
            <Spinner animation="border" variant="primary" role="status">
              <span className="sr-only">Loading dmarc data...</span>
            </Spinner>
          ) : dkimresponse ? (
            <div className="dkim-table-container">
              <table className="table table-dkim table-bordered table-striped">
                <tbody>
                  <tr>
                    <th className="dkim-header" colSpan={3}>
                      Your DKIM Details
                    </th>
                  </tr>
                  <tr>
                    <td>Version</td>
                    <td>{dkimresponse.v}</td>
                    <td>
                      The DKIM version should always be "DKIM1". Note: A wrong,
                      or absent DKIM version tag can cause the entire record to
                      be ignored
                    </td>
                  </tr>
                  <tr>
                    <td>Algorithm</td>
                    <td>{dkimresponse.algorithm}</td>
                    <td>The algorithm used to encrypt email</td>
                  </tr>
                  <tr>
                    <td>Key</td>
                    <td>{dkimresponse.public_key}</td>
                    <td>Public key used to encrypt messages</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </>
    );
  };

  const MxServer = (props) => {
    const [mxresponse, setmxresponse] = useState(false);
    const [mxerror, setmxerror] = useState(false);
    const [mxreport, setmxreport] = useState(false);
    const [mxLoader, setmxloader] = useState(false);
    const [emailserverresponse, setemailserverresponse] = useState(false);
    const [emailserverresponseLoader, setemailserverresponseLoader] =
      useState(false);
    const [reloadmxtable, setreloadmxtable] = useState(false);

    let currentselecteddomain = props.domain;

    useEffect(
      () => getmxRecords(currentselecteddomain),
      [inputValue, reloadmxtable]
    );

    const getmxRecords = async (domain) => {
      setmxreport(true);
      setmxloader(true);
      let response = await fetch(`${host}/tools/mxcheck?domain=${domain}`, {
        method: "get",
      });
      let json_response = await response.json();
      if (json_response.error !== true) {
        setmxresponse(json_response);
        setmxloader(false);
        setmxerror(false);
        return json_response;
      } else {
        setmxreport(true);
        setmxerror(json_response);
        setmxloader(false);
      }
    };

    const checkEmailServer = async (e) => {
      // loop through all responses containers and hide them
      const containers = document.getElementsByClassName(
        "email-server-responses-container"
      );
      const container_array = Array.prototype.slice.call(containers);
      container_array.forEach((element) => {
        element.style.display = "none";
      });
      setemailserverresponseLoader(true);
      // show spinner
      e.target.nextElementSibling.style.visibility = "visible";
      setemailserverresponse(false);
      let server_to_check =
        e.target.parentElement.parentElement.children[0].textContent;
      let response = await fetch(
        `${host}/tools/emaildeliverabilitycheck?domain=${server_to_check}`,
        {
          method: "get",
        }
      );
      let json_response = await response.json();
      json_response["server_to_check"] = server_to_check;
      setemailserverresponse(json_response);
      setemailserverresponseLoader(false);
      // hide spinner
      e.target.nextElementSibling.style.visibility = "hidden";
      // show view results button
      e.target.nextElementSibling.nextElementSibling.style.visibility =
        "visible";
      // show results
      e.target.nextElementSibling.nextElementSibling.nextElementSibling.style.display =
        "block";
    };

    return (
      <div className="mxresponse-container">
        {mxerror ? <div>MX Error{mxerror.errormsg}</div> : ""}
        {mxreport ? (
          mxLoader ? (
            <Spinner animation="border" variant="primary" role="status">
              <span className="sr-only">Loading mx data...</span>
            </Spinner>
          ) : (
            <>
              {mxresponse ? (
                <div className="email-server-list-headers">
                  Email Servers for {" " + inputValue}
                </div>
              ) : (
                ""
              )}

              {mxresponse ? (
                <>
                  {/* <button style = {{margin:"10px"}} className = "btn btn-primary"></button> */}
                  <table className="table table-mxserver table-bordered table-striped">
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <th>Server Name</th>
                      <th>Experimental Feature </th>
                    </tr>
                    {mxresponse.mx_server.map((server) => (
                      <tr>
                        <td>{server}</td>
                        <td>
                          <>
                            <button
                              onClick={(e) => {
                                checkEmailServer(e);
                              }}
                              className="btn btn-success check-mx-server"
                            >
                              Check Server
                            </button>

                            <>
                              <Spinner
                                style={{
                                  visibility: "hidden",
                                  marginLeft: "20px",
                                }}
                                animation="border"
                                variant="primary"
                                role="status"
                              ></Spinner>
                              <button
                                className="btn btn-primary mxserver-results"
                                style={{ visibility: "hidden" }}
                                onClick={(e) => {
                                  const response_container =
                                    e.target.nextElementSibling;
                                  if (
                                    response_container.style.display !== "none"
                                  ) {
                                    response_container.style.display = "none";
                                    e.target.textContent = "Show Results";
                                  } else {
                                    response_container.style.display = "block";

                                    e.target.textContent = "Hide Results";
                                  }
                                }}
                              >
                                Hide Results
                              </button>
                              <div className="email-server-responses-container">
                                {emailserverresponse !== false ? (
                                  emailserverresponse.error ? (
                                    <div className="emailserver-error">
                                      {emailserverresponse.errormsg}
                                    </div>
                                  ) : (
                                    <div className="emailserver-response-container">
                                      <table className="table table-dkim table-bordered table-striped">
                                        <tbody>
                                          <tr>
                                            <th colSpan={2}>
                                              Response from{" "}
                                              {" " +
                                                emailserverresponse[
                                                  "server_to_check"
                                                ]}
                                            </th>
                                          </tr>
                                          <tr>
                                            <td>Resolved address</td>
                                            <td>
                                              The server ip resolved to{" "}
                                              {emailserverresponse.resolved_name.map(
                                                (server) => server + " "
                                              )}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>SMTP TLS</td>
                                            <td>
                                              {emailserverresponse.TLS
                                                ? "OK Supports TLS"
                                                : "Does not Support TLS"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Server Connection Time</td>
                                            <td>
                                              {
                                                emailserverresponse.server_response_time
                                              }
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>SMTP Reverse DNS Mismatch</td>
                                            <td>
                                              {emailserverresponse.reverse_dns_mismatch
                                                ? "The forward lookup (A) of the hostname hostname matched the reverse lookup (PTR) for the IP Address "
                                                : "The forward lookup (A) of the hostname hostname did not match the reverse lookup (PTR) for the IP Address"}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <div className="server-actual-response">
                                        {emailserverresponse.responses.map(
                                          (response) => (
                                            <span className="response-emailserver single">
                                              {response}
                                            </span>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                            </>
                          </>
                        </td>
                      </tr>
                    ))}
                  </table>
                </>
              ) : (
                ""
              )}
            </>
          )
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="home-page-image">
            <img
              style={{ width: "70%", height: "400px" }}
              src={homeimage}
            ></img>
            <div className="text-over-image">
              <div>Why choose Netpillar?</div>
              <div>
                Netpillar Email Security suite brings custom solution to
                businesses of all shapes and sizes. We build our solution from
                ground up specially for small businesses where they necessarily
                don't have a dedicated IT team or even a personnel.
              </div>
            </div>
          </div>
          <div className="search-form">
            <span>Check your domain</span>
            <input
              onChange={handleChange}
              className="form-input-search"
            ></input>
            <button
              disabled={!inputValue}
              onClick={() => checkDomain(inputValue)}
              className="btn btn-primary"
            >
              CHECK MY DOMAIN
            </button>
          </div>
          <DmarcReports></DmarcReports>
          {spfresponse ? (
            <SpfReport></SpfReport>
          ) : (
            <>
              {spfLoader ? (
                <Spinner animation="border" variant="primary" role="status">
                  <span className="">Loading SPF data...</span>
                  <span className="sr-only">Loading data...</span>
                </Spinner>
              ) : (
                ""
              )}
            </>
          )}
          {spferror ? (
            <div className="spf-error">SPF ERROR:{" " + spferror.errormsg}</div>
          ) : (
            ""
          )}
          {spfresponse ? <DKIMReport></DKIMReport> : ""}
          {spfresponse ? <MxServer domain={inputValue}></MxServer> : ""}
        </div>
      </div>
    </>
  );
};

export default DmarcHome;
