import {
  CardChecklist,
  GraphUp,
  Shield,
  ShieldCheck,
} from "react-bootstrap-icons";

import React from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const Faq = () => {
  let frontend = process.env.REACT_APP_FRONTEND;
  return (
    <>
      <Row>
        <Col md={12}>
          <Card className="dmarc-card">
            {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
            <Card.Body>
              <Card.Title>Why use DMARC</Card.Title>
              <Card.Body>
                <div className="card-text-wrapper">
                  <h4>Get insights into your email security.</h4>
                  <h6>
                    Stop spoofing attacks and block email impersonation with
                    Netpillar DMARC Solution<br></br>
                    Follow clear actions to secure your inbox and boost email
                    deliverability
                  </h6>
                </div>
              </Card.Body>
              <Button
                onClick={() =>
                  (window.location.href = `${process.env.REACT_APP_FRONTEND}/${process.env.REACT_APP_DMARC_ROUTE}`)
                }
                variant="primary"
              >
                Join Now
              </Button>
              <Button className="join-now">
                <Link to={`/contact-us`}>
                  <span className="nav-link-text">Contact us</span>
                </Link>
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <div className="highlight-info">
        <div>96% of breaches happen via email</div>
        <div>1.5M new phishing websites are created each month</div>
        <div>$26B total number of losses from BEC scams</div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="faq-content-wrapper">
            <div className="faq-content-single-block">
              <h4>
                <span>
                  <CardChecklist></CardChecklist>
                </span>
                Get insight into who is using your domain to send fake emails
              </h4>
            </div>
            <div className="faq-content-single-block">
              <h4>
                <span>
                  <GraphUp></GraphUp>
                </span>{" "}
                We provide you with easy interface that so that you dont need an
                expert to understand your reports
              </h4>
            </div>

            <div className="faq-content-single-block">
              <h4>
                <span>
                  {" "}
                  <ShieldCheck></ShieldCheck>
                </span>
                Elevate your email reputation.
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="dmarc-qa-wrapper">
            <div className="dmarc-q">Why is DMARC important?</div>
            <div className="dmarc-ans">
              With the rise of the social internet and the ubiquity of
              e-commerce, spammers and phishers have a tremendous financial
              incentive to compromise user accounts, enabling theft of
              passwords, bank accounts, credit cards, and more. Email is easy to
              spoof and criminals have found spoofing to be a proven way to
              exploit user trust of well-known brands. Simply inserting the logo
              of a well known brand into an email gives it instant legitimacy
              with many users. Users can’t tell a real message from a fake one,
              and large mailbox providers have to make very difficult (and
              frequently incorrect) choices about which messages to deliver and
              which ones might harm users. Senders remain largely unaware of
              problems with their authentication practices because there’s no
              scalable way for them to indicate they want feedback and where it
              should be sent. Those attempting new SPF and DKIM deployment
              proceed very slowly and cautiously because the lack of feedback
              also means they have no good way to monitor progress and debug
              problems. DMARC addresses these issues, helping email senders and
              receivers work together to better secure emails, protecting users
              and brands from painfully costly abuse.
            </div>

            <div className="dmarc-q">
              What is DMARC, and how does it combat phishing?
            </div>
            <div className="dmarc-ans">
              DMARC is a way to make it easier for email senders and receivers
              to determine whether or not a given message is legitimately from
              the sender, and what to do if it isn’t. This makes it easier to
              identify spam and phishing messages, and keep them out of peoples’
              inboxes. DMARC is a proposed standard that allows email senders
              and receivers to cooperate in sharing information about the email
              they send to each other. This information helps senders improve
              the mail authentication infrastructure so that all their mail can
              be authenticated. It also gives the legitimate owner of an
              Internet domain a way to request that illegitimate messages –
              spoofed spam, phishing – be put directly in the spam folder or
              rejected outright.
            </div>

            <div className="dmarc-q">
              Does DMARC block all types of phishing attacks?
            </div>
            <div className="dmarc-ans">
              No. DMARC is only designed to protect against direct domain
              spoofing. If the owners/operators of example.com use DMARC to
              protect that domain, it would have no effect on otherdomain.com or
              example.net (notice the ".net" vs. ".com"). While impersonating a
              given domain is a common method used for phishing and other
              malicious activites, there are other attack vectors that DMARC
              does not address. For example, DMARC does not address cousin
              domain attacks (i.e. sending from a domain that looks like the
              target being abused - e.g. exampl3.com vs. example.com), or
              display name abuse (i.e. modifying the "From" field to look as if
              it comes from the target being abused).
            </div>
            <div className="dmarc-q">
              What type of illegitimate email does DMARC address?
            </div>
            <div className="dmarc-ans">
              DMARC is designed to protect against direct domain spoofing. When
              an email is sent by an unauthorized sender (whether it is sent by
              a malicious actor, or even an unauthorized or non-participating
              department of the company that owns/operates the domain), DMARC
              can be used to detect the unauthorized activity and (if so
              configured) request that those messages be blocked or discarded
              when they are received.
            </div>
            <div className="dmarc-q">
              How does DMARC work, briefly, and in non-technical terms?
            </div>
            <div className="dmarc-ans">
              A DMARC policy allows a sender to indicate that their messages are
              protected by SPF and/or DKIM, and tells a receiver what to do if
              neither of those authentication methods passes – such as junk or
              reject the message. DMARC removes guesswork from the receiver’s
              handling of these failed messages, limiting or eliminating the
              user’s exposure to potentially fraudulent & harmful messages.
              DMARC also provides a way for the email receiver to report back to
              the sender about messages that pass and/or fail DMARC evaluation.
            </div>

            <div className="dmarc-q">Why is DMARC needed?</div>
            <div className="dmarc-ans">
              End users and companies all suffer from the high volume of spam
              and phishing on the Internet. Over the years several methods have
              been introduced to try and identify when mail from (for example)
              IRS.GOV really is, or really isn’t coming from the IRS. However:
              These mechanisms all work in isolation from each other Each
              receiver makes unique decisions about how to evaluate the results
              The legitimate domain owner (e.g. IRS) never gets any feedback
              DMARC attempts to address this by providing coordinated, tested
              methods for: Domain owners to: Signal that they are using email
              authentication (SPF, DKIM) Provide an email address to gather
              feedback about messages using their domain – legitimate or not A
              policy to apply to messages that fail authentication (report,
              quarantine, reject) Email receivers to: Be certain a given sending
              domain is using email authentication Consistently evaluate SPF and
              DKIM along with what the end user sees in their inbox Determine
              the domain owner’s preference (report, quarantine or reject) for
              messages that do not pass authentication checks Provide the domain
              owner with feedback about messages using their domain A domain
              owner who has deployed email authentication can begin using DMARC
              in “monitor mode” to collect data from participating receivers. As
              the data shows that their legitimate traffic is passing
              authentication checks, they can change their policy to request
              that failing messages be quarantined. As they grow confident that
              no legitimate messages are being incorrectly quarantined, they can
              move to a “reject” policy.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Faq };
