import { useEffect } from "react";
import { useState } from "react";

export const useReactPath = () => {
  const [path, setPath] = useState(window.location.pathname);
  const refresh = () => {
    window.dispatchEvent(new Event("popstate"));
  };
  const listenToPopstate = () => {
    const winPath = window.location.pathname;
    setPath(winPath);
  };
  // var observer = new MutationObserver(function (mutations) {
  //   if (window.location.pathname !== path) {
  //     setPath(window.location.pathname);
  //   }
  // });
  useEffect(() => {
    window.addEventListener("popstate", listenToPopstate);
    // observer.observe(document, {
    //   subtree: true,
    //   childList: true,
    //   attributes: true,
    // });
    return () => {
      // observer.disconnect();
      window.removeEventListener("popstate", listenToPopstate);
    };
  }, []);
  return { path, refresh };
};
