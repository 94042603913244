import React, { useEffect, useState } from "react";
import { Products } from "../constant.js";
import Product from "../ProductComponent/Product";

const StripeContainer = (props) => {
  const { setProduct, product } = props;
  const [stripePlans, setStripePlans] = useState("");
  let host = process.env.REACT_APP_BACKEND;

  useEffect(() => {
    const products = async () => {
      const response = await fetch(`${host}/payment/stripe-products`, {
        credentials: "include",
        method: "get",
      });
      let result = await response.json();
      setStripePlans(result);
    };
    products();
  }, []);
  return (
    <div className="stripe-container">
      {stripePlans
        ? stripePlans.plans.map((x) => (
            <Product
              key={x.id}
              title={x.name}
              price={x.price / 100}
              className="product-stripe"
              onClick={() => setProduct(x)}
              isChecked={x === product}
            />
          ))
        : ""}
    </div>
  );
};

export default StripeContainer;
