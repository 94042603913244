import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";
import { DomainSelector } from "../Report/reportgenerator";

const AddUsers = (props) => {
  const [selectedDomain, setSelectedDomain] = useState(false);
  const [formReady, setformReady] = useState(false);
  const [input, setInput] = useState("");
  const [role, setrole] = useState("0");
  let host = process.env.REACT_APP_SERVERLESS_LOGIN;

  let validateForm = () => {
    if (selectedDomain === false && input === "" && role === "0") {
      setformReady(false);
      return false;
    }
    if (input !== "" && role === "admin") {
      setformReady(true);
      return true;
    }

    if (selectedDomain !== "0" || (false && input !== "" && role === "user")) {
      setformReady(true);
      return true;
    }

    if (selectedDomain === "0" && input !== "" && role === "admin") {
      setformReady(true);
      return true;
    }
    if (selectedDomain !== "0" && input !== "" && role === "user") {
      setformReady(true);
      return true;
    }

    if (!selectedDomain && input !== "" && role === "user") {
      setformReady(false);
      return false;
    }

    if (!selectedDomain && input !== "" && role === "admin") {
      setformReady(true);
      return true;
    }
    return false;
  };

  const handleRoleChange = (e) => {
    if (e.target.value === "0") {
      setrole(e.target.value);
    } else {
      setrole(e.target.value);
    }
  };

  const handleInputChange = (e) => {
    validateForm();
    setInput(e.target.value);
  };
  const submitUserCreateForm = (e) => {
    e.preventDefault();
    let result = validateForm();
    if (result === false) {
      return;
    }

    let form = new FormData();
    form.append("selected_domain", selectedDomain);
    form.append("samluserid", input);
    form.append("role", role);
    let add_saml_users = async () => {
      props.setoverlaystatus(true);
      props.setresponse(false);
      let result = await fetch(`${host}/api/auth/saml/add_saml_users`, {
        method: "post",
        credentials: "include",
        body: form,
      });
      let hostresponse = await result.json();
      if (hostresponse.status === "success") {
        props.setoverlaystatus(false);
        props.setresponse(hostresponse);
        setSelectedDomain(false);
        setInput("");
        props.setreload(!props.reload);
      }
      if (hostresponse.status === "failure") {
        props.setoverlaystatus(false);
        props.setresponse(hostresponse);
        props.setreload(true);
        setSelectedDomain(false);
        setInput("");
        props.setreload(!props.reload);
      }
    };

    add_saml_users();
  };

  useEffect(() => {
    let result = validateForm();
    result ? setformReady(true) : setformReady(false);
  }, [input, role, selectedDomain, props.reload]);
  // Add Saml user
  return (
    <>
      <Form>
        <div className="adduserwrapper">
          <div className={"userfields"}>
            <div>
              <label aria-required htmlFor="samluseremail"></label>
              <input
                validate={true}
                type="email"
                value={input}
                onInput={handleInputChange}
                required
                className="form-control"
                id="samluseremail"
                placeholder="Enter username"
              ></input>
              <small>
                <InfoCircle></InfoCircle>This should match the user id sent by
                the idp
              </small>
            </div>
            <div>
              <div>
                <select className="form-control" onChange={handleRoleChange}>
                  <option value={"0"}>Select Role</option>
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                </select>
                <small>
                  {role === "admin"
                    ? "Admin gets access to all the domains"
                    : "User only has access to domain it is authorised"}
                </small>
              </div>

              <>
                <DomainSelector
                  validate={validateForm}
                  disabled={role == "admin"}
                  multiple_selector={true}
                  setSelectedDomain={setSelectedDomain}
                  setformReady={setformReady}
                ></DomainSelector>
                <small>Use shift to select multiple domains</small>
              </>

              <div>
                <Button
                  disabled={!formReady}
                  type="submit"
                  onClick={submitUserCreateForm}
                >
                  Add SAML User
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form>
      {props.response ? (
        props.response.status ? (
          <div className="alert alert-success">{props.response.msg}</div>
        ) : (
          ""
        )
      ) : (
        ""
      )}
    </>
  );
};

const ShowUsers = (props) => {
  const [userslist, setuserslist] = useState(false);
  let host = process.env.REACT_APP_SERVERLESS_LOGIN;

  useEffect(() => {
    getUsers();
  }, [props.reload]);

  const getUsers = async () => {
    props.setoverlaystatus(true);
    const response = await fetch(`${host}/api/auth/saml/get_saml_users`, {
      method: "post",
      credentials: "include",
    });

    let users_list_response = await response.json();
    if (users_list_response["data"]) {
      setuserslist(users_list_response["data"]);
      props.setoverlaystatus(false);
    }
  };
  return userslist
    ? userslist.map((item) => {
        return (
          <tr>
            <td>{item.role}</td>
            <td>{item.email}</td>
            {Array.isArray(item.domain) ? (
              <td>
                {item.domain.map((domain) => (
                  <span
                    className="individual-domain rounded-lg"
                    style={{ background: "#3a64a2" }}
                  >
                    {domain.name ? domain.name : domain}
                  </span>
                ))}
              </td>
            ) : (
              <td>{item.domain}</td>
            )}
            <td>
              <button
                onClick={props.remove_user}
                className="btn btn-danger rounded-lg"
                style={{ background: "rgb(226, 71, 71)" }}
              >
                Remove User
              </button>
            </td>
          </tr>
        );
      })
    : "0 SAML users found";
};

export { AddUsers, ShowUsers };
