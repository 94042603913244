import React, { useState } from "react";

const serverlessLogin = process.env.REACT_APP_SERVERLESS_LOGIN;

function FileUploadPage(props) {
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [checkFileResult, setCheckFileResult] = useState("");
  const [metadataresponse, setmetadataresponse] = useState(false);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const check_extension = (file) => {
    if (file.type === "text/xml") {
      let result = "";
      let reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload = function (evt) {
        let content = evt.target.result;
        if (content.length <= 0) {
          result = "File is empty";
          setCheckFileResult(result);
          return true;
        }
      };
      reader.onerror = function (evt) {
        result = "An error occured.Please try again";
        setCheckFileResult(result);
        return true;
      };
    } else {
      let result = "Idp Metadata must be a valid xml";
      setCheckFileResult(result);
      return false;
    }
  };

  const handleSubmission = (evt) => {
    let result = check_extension(selectedFile);
    props.setoverlaystatus(true);
    if (result !== false) {
      // do nothing
    } else {
      props.setoverlaystatus(false);
      return;
    }
    const formData = new FormData();

    formData.append("File", selectedFile);
    props.setoverlaystatus(true);
    fetch(`${serverlessLogin}/api/auth/saml/upload/idpmetadata`, {
      method: "POST",
      body: formData,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((result) => {
        setmetadataresponse(result);
        props.setoverlaystatus(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="metadatauploadwrapper">
      {!metadataresponse ? (
        <>
          <input
            className="form-control"
            type="file"
            name="file"
            onChange={changeHandler}
          />
          {isSelected ? (
            checkFileResult !== "" ? (
              <div className="alert alert-warning">{checkFileResult}</div>
            ) : (
              ""
            )
          ) : (
            <p>Select a metadata file provided by idp</p>
          )}
          <div>
            {isSelected ? (
              <button
                disabled={metadataresponse ? true : false}
                className="btn btn-primary"
                onClick={handleSubmission}
              >
                Submit
              </button>
            ) : (
              <button
                className="btn btn-success"
                disabled={true}
                onClick={handleSubmission}
                style={{ backgroundColor: "#3a64a2" }}
              >
                Submit
              </button>
            )}
          </div>
        </>
      ) : (
        ""
      )}
      {metadataresponse.status === "success" ? (
        <div className="upload-response">
          <>
            <div className="upload-headers-text">
              <span>Your metadata has been uploaded and processed.</span>
              <span>Review the details below</span>
            </div>
            <div className="upload-response-text saml-details">
              <span>Your SAML details for IDP initiated login</span>
              <div>
                <div>Status: {metadataresponse.msg}</div>
                <div>ACS URL: {metadataresponse.saml_data.acs_url}</div>
                <div>
                  METADATA URL: {metadataresponse.saml_data.metadata_url}
                </div>
              </div>
              <div className="save-instructions">
                Copy this data as you may need it for setting up your IDP
              </div>
            </div>
          </>
        </div>
      ) : (
        <div>
          {metadataresponse.status === "failure" ? (
            <div>Status: {metadataresponse.msg}</div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
}

export default FileUploadPage;
