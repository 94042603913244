import React, { useState, useEffect, lazy, Suspense } from "react";
import { useAxios } from "../../../hooks/useAxios";
import { PayPalSubscribeButtons } from "../PaypalComponent/PayPalSubscribeButtons";

const WizardFooter = (props) => {
  const { payment, SW, product, domain } = props;
  const [error, seterror] = useState(false);
  const [result, setresult] = useState(false);
  const { doCall, status, response } = useAxios();
  const [nextButtonDisabled, setnextButtonDisabled] = useState(false);
  let host = process.env.REACT_APP_BACKEND;

  const handleClickStripe = () => {
    props.setoverlay(true);
    var fd = new FormData();
    product && fd.append("lookup_key", product.id);
    const callStripe = async () => {
      const response = await fetch(`${host}/payment/${payment}-checkout`, {
        credentials: "include",
        method: "post",
        body: fd,
      });
      let result_response = await response.json();
      props.setoverlay(false);
      setresult(result_response);
      if (result_response.status === "success") {
        window.location.replace(result_response.redirect_url);
      }
      if (result_response.status === "failure") {
        seterror(result_response);
      }
    };
    callStripe();
  };

  const ButtonGroup = (props) => {
    return (
      <div className="wizard-buttons">
        <div className="btn-group">
          <button
            type="button"
            className="btn-previous"
            onClick={() => SW.firstStep()}
          >
            Previous
          </button>
          <button
            disabled={nextButtonDisabled == true}
            type="button"
            className="btn-next"
            onClick={nextButton}
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  const nextButton = () => {
    if (product == undefined && payment == "") {
      SW.goToNamedStep("payment_selection");
    }
    if (product == undefined && payment == "stripe") {
      SW.goToNamedStep("stripe");
    }
    if (product == undefined && payment == "paypal") {
      SW.goToNamedStep("paypal");
    }
    if (product != undefined) {
      if (payment == "stripe") {
        handleClickStripe();
        setnextButtonDisabled(false);
      }
      if (payment == "paypal" && product.paymentType == "paypal") {
        SW.goToNamedStep("paypalbuttons");
      }
    }
  };

  return (
    <>
      <ButtonGroup></ButtonGroup>
      {error ? <div className="alert alert-danger">{error.msg}</div> : ""}
    </>
  );
};

export default WizardFooter;
