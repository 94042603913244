import React, { useEffect, useState } from "react";
import { Data_analyser, makeGeoData } from "../DmarcCore/dataAnalyser.js";
import { Data_modifier } from "../DmarcCore/datamodifier";
import { Overlay } from "../uihelpers/overlay.js";

function ReportDateFilters(props) {
  const getstartdates = (e) => {
    props.setstartdates(e.target.value);
  };

  const getenddates = (e) => {
    props.setenddates(e.target.value);
  };

  return (
    <>
      <label>Start Date</label>
      <input
        required
        className="form-control"
        onChange={(e) => getstartdates(e)}
        onClick={(e) => e.stopPropagation()}
        type="date"
        id="dmarc-date-filter-start"
        name="start_date"
      ></input>
      <label>End Date</label>
      <input
        required
        className="form-control"
        onChange={(e) => getenddates(e)}
        onClick={(e) => e.stopPropagation()}
        type="date"
        id="dmarc-date-filter-end"
        name="end_date"
      ></input>
    </>
  );
}

const DomainSelector = (props) => {
  let host = process.env.REACT_APP_BACKEND;
  const [authorisedDomains, setauthorisedDomains] = useState(false);

  useEffect(() => {
    fetch(`${host}/get_authorised_domains`, {
      method: "post",
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((response_json) => {
        setauthorisedDomains(response_json.data);
      });
  }, []);

  const handleChange = (e) => {
    if (props.multiple_selector == true) {
      var options = e.target.options;
      var value = [];
      for (var i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
      if (value.length > 1) {
        props.setSelectedDomain(value);
      } else {
        props.setSelectedDomain(value[0]);
      }
    }
    if (!Array.isArray(value)) {
      if (e.target.value != "0") {
        props.setSelectedDomain(e.target.value);
      }
      if (props.validate) {
        props.validate();
      }
    }
  };

  const is_multiple = (props) => {
    if (props.multiple_selector == true) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <select
      disabled={props.disabled}
      multiple={is_multiple(props)}
      className="form-control"
      onChange={handleChange}
    >
      <option value={"0"} key={0}>
        Select Domain
      </option>
      {props.children}
      {authorisedDomains ? (
        authorisedDomains.map((item, key) => (
          <option key={key} value={item["name"]}>
            {item["name"]}
          </option>
        ))
      ) : (
        <option>Loading domains....</option>
      )}
    </select>
  );
};

const ReportGenerator = (props) => {
  // this component needs to have filter dates
  // gather data for a particular domain
  // so need domain selector,
  let host = process.env.REACT_APP_BACKEND;
  const [startdates, setstartdates] = useState(false);
  const [enddates, setenddates] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(false);
  const [formready, setformReady] = useState(false);
  const [downloadReportLinks, setDownloadReportLinks] = useState(false);
  const [nodatafound, setnodatafound] = useState(false);
  const [overlayactive, setoverlayactive] = useState(false);

  const formatted_start_date = `${new Date(startdates).getFullYear()}/${
    new Date(startdates).getMonth() + 1
  }/${
    new Date(startdates).getDate() > 9
      ? new Date(startdates).getDate()
      : "0" + new Date(startdates).getDate()
  }`;

  const formatted_end_date = `${new Date(enddates).getFullYear()}/${
    new Date(enddates).getMonth() + 1
  }/${
    new Date(enddates).getDate() > 9
      ? new Date(enddates).getDate()
      : "0" + new Date(enddates).getDate()
  }`;

  console.log(new Date(formatted_start_date).getTime() / 1000);
  console.log(new Date(formatted_end_date).getTime() / 1000);

  const getdmarcData = async (count) => {
    const dateFilter = {
      size: count.count,
      query: {
        bool: {
          must: [
            {
              match: {
                published_domain: selectedDomain,
              },
            },
            {
              range: {
                date_range: {
                  lt: new Date(formatted_end_date).getTime() / 1000,
                  gt: new Date(formatted_start_date).getTime() / 1000,
                },
              },
            },
          ],
        },
      },

      sort: [{ date_range: { order: "desc", mode: "max" } }],
    };

    let data = await fetch(`${host}/dataendpoint`, {
      method: "post",
      body: JSON.stringify({
        selected_domain: selectedDomain,
        query: dateFilter,
      }),
      credentials: "include",
    });
    let response = await data.json();
    // the response may give zero results
    let modifier = new Data_modifier(response);
    let analyser = new Data_analyser(response);
    let results = analyser.get_results();
    analyser.ip_analyser(results.ip_results);
    let modified_results = modifier.group_by_organisation();
    let modified_results_analysed = modifier.results_by_organisation_analysed(
      results.ip_records,
      modified_results
    );
    results.results_by_org = modified_results_analysed;
    results.geo_ip_results = makeGeoData(results.ip_records);
    return results;
  };

  const getData = async (e) => {
    e.preventDefault();
    setoverlayactive(true);
    const count = await fetch(`${host}/getcount`, {
      method: "post",
      credentials: "include",
      body: JSON.stringify({ selected_domain: selectedDomain }),
    });
    let countResponse = await count.json();
    if (countResponse.count === 0) {
      setnodatafound(true);
      setoverlayactive(false);
      return;
    }
    let data = await getdmarcData(countResponse);
    if (data.total_email_count === 0) {
      setnodatafound(true);
      setoverlayactive(false);
      return;
    }
    const analysed_data = await fetch(`${host}/reports`, {
      method: "post",
      credentials: "include",
      body: JSON.stringify({ analysed_data: data }),
    });
    let response = await analysed_data.json();
    setoverlayactive(false);
    setDownloadReportLinks(response);
  };

  return (
    <>
      <Overlay activate={overlayactive}> </Overlay>

      <div className="filter-form-report">
        <form className="report-form" onSubmit={(e) => getData(e)}>
          <ReportDateFilters
            startdates={startdates}
            enddates={enddates}
            setstartdates={setstartdates}
            setenddates={setenddates}
          ></ReportDateFilters>
          <DomainSelector
            setSelectedDomain={setSelectedDomain}
            setformReady={setformReady}
          ></DomainSelector>
          <button type="submit" className="get-report-button btn btn-primary">
            Get Report
          </button>

          {downloadReportLinks ? (
            <div className="download-link">
              <a href={downloadReportLinks.url}>Click to download your file</a>
            </div>
          ) : (
            ""
          )}
          {nodatafound && !downloadReportLinks ? (
            <div className="download-link-nodatafound">
              <span>No data found for the criteria provided</span>
            </div>
          ) : (
            ""
          )}
        </form>
      </div>
    </>
  );
};

export { ReportGenerator, DomainSelector };
